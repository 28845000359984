import * as React from "react";
import { Dropdown, List, Menu } from "antd";
import { AzureBlobIcon, AzureDataLakeIcon, S3Icon } from "@assets/connectionTypeIcon";
import "../styles.scss";
import { StorageConnectionData } from "../types";
import { ToggleField } from "@components/form/toggle-field";
import { InPageSpinner } from "@components/spinners/in-page-spinner";
import { useDispatch } from "react-redux";
import { setPathInfo } from "@store/account";

const imagePathRef = {
	AzureBlobStorage: <AzureBlobIcon />,
	AzureDataLake: <AzureDataLakeIcon />,
	S3: <S3Icon />,
};

type ConnectionsProps = {
	connections: StorageConnectionData[];
	setStorageConnectionModal: (value: boolean) => void;
	setStorageConnectionDataModal: (data: StorageConnectionData) => void;
	handleDeleteConnection: (data: StorageConnectionData) => void;
	handleEnableConnection: (data: StorageConnectionData) => void;
	isLoading: boolean;
};

export const ConnectionList: React.FC<ConnectionsProps> = ({
	connections,
	setStorageConnectionModal,
	setStorageConnectionDataModal,
	handleDeleteConnection,
	handleEnableConnection,
	isLoading,
}: ConnectionsProps) => {
	const onEditConnection = React.useCallback(
		(data: StorageConnectionData) => {
			setStorageConnectionDataModal(data);
			setStorageConnectionModal(true);
		},
		[]
	);

	const dispatch=useDispatch();

	const moreActions = (item: StorageConnectionData) => (
		<Menu>
			<Menu.Item
				key="enable"
				onClick={() => handleEnableConnection(item)}
			>
				{item.isEnabled ? "Disable" : "Enable"}
			</Menu.Item>
			<Menu.Item key="edit" onClick={() => onEditConnection(item)}>
				Edit Connection Details
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item
				key="delete"
				onClick={() => handleDeleteConnection(item)}
			>
				Delete
			</Menu.Item>
		</Menu>
	);

	return (
		<div className="list_container">
			{isLoading ? (
				<div className="in_center">
					<InPageSpinner />
				</div>
			) : (
				<List
					itemLayout="horizontal"
					dataSource={connections}
					renderItem={(item) => {
						const imgSrc =
							imagePathRef[
								item.storageConnectionType as keyof typeof imagePathRef
							];
						return (
							<List.Item
								style={{
									backgroundColor: item.isEnabled
										? "#5B608F"
										: "#212233",
								}}
								key={item.id}
								actions={[
									<div key={item.id} className="list_item">
										<ToggleField
											active={item.isEnabled}
											onClick={() => {
												handleEnableConnection(item);
												dispatch(setPathInfo({ id: "default", name: "default" }));
											}}
										/>
										<Dropdown
											overlayClassName="more-action-container"
											overlay={() => moreActions(item)}
											trigger={["click"]}
										>
											<div className="more_icon">
												<img src="/icons/more.svg" />
											</div>
										</Dropdown>
									</div>,
								]}
							>
								<List.Item.Meta
									avatar={
										<div className="list_item_avatar">
											{imgSrc}
										</div>
									}
									title={<div>{item.connectionName}</div>}
									description={
										<div>{item.storageConnectionType}</div>
									}
								/>
							</List.Item>
						);
					}}
				/>
			)}
		</div>
	);
};
